import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './actions';
import { SystemInformationsService } from '@app/services/system-informations.service';

@Injectable()
export class SystemInformationsStoreEffects {
  constructor(
    private dataService: SystemInformationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  // Restore this effect to make the store working again
  // load$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.loadRequest),
  //     switchMap(() =>
  //       this.dataService.load().pipe(
  //         map((response) => fromActions.loadSuccess({ data: response.data })),
  //         catchError((error) => {
  //           this.errorHandler.handle(error);
  //           return of(fromActions.loadFailure(error));
  //         }),
  //       ),
  //     ),
  //   ),
  // );
}
